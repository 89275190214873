html {
  height: 100%;
  background: #ffb7c3 !important;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ffb7c3 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table {
  font-family: arial, sans-serif;
  border: none !important;
  /* border-collapse: collapse; */
  color: black !important;
  text-align: center !important;
  background: #ffb7c3 !important;
  width: 100%;
}

table td,
table th,
table tbody,
.ant-table-wrapper .ant-table-tbody >tr >td {
  text-align: center !important;
  background: #ffb7c3 !important;
  color: black !important;
  border: none !important;
}

table thead {
  border-bottom: 1px solid gray;
}

th:before {
  background: none !important;
}

td, th, tr {
  text-align: left;
  border-bottom: 1px solid gray !important;
  padding: 0px !important;
  font-size: 28px !important;
  color: black !important;
  text-align: center !important;
  /* border: 1px solid #dddddd; */
  /* border: none !important; */
  background: #ffb7c3 !important;

}

tr:nth-child(even) {
  background-color: #dddddd;
  background: #ffb7c3 !important;
  color: black !important;
  border: none !important;
  text-align: center !important;
}

h1 {
  margin: 0;
}

.title {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 55px;
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.blink {
  /* animation: blink 2s infinite; */
  text-shadow:  0 0 5px white,0 0 10px #00FFFF,0 0 15px #7FFF00,0 0 20px white;
}