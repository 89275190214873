.App {
  text-align: center;
  background: rgba(0,0,0,0.7);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* 
.adm-tab-bar {
    bottom: 0;
    position: fixed;
    width: 100%;
} */
body, html { 
  font-family:  "Dancing_Script variant0", Tofu;
}
/* table,
table tr,
table tbody > tr > td,
table thead tr th,
.customize-table {
  background: rgba(0,0,0,0.7) !important;
  color: rgb(255, 255, 255) !important;
  font-size: 20px;
  font-weight: bold;
} */

